import CardComponent from "@/core/shared/card/card.component.vue";
import {mapGetters} from "vuex";
import SwiperCore, {Navigation} from 'swiper';
import ModalComponent from "@/core/shared/modal/modal.component.vue";
import 'swiper/components/navigation/navigation.scss';

SwiperCore.use([Navigation]);

export default {
    name: "project-details.component",
    components:{
        'card-component': CardComponent,
         ModalComponent
    },
    data(){
        return{
            breakpoints: {
                320: {
                    slidesPerView: 1,
                },
                767: {
                    slidesPerView: 2,
                },
                // 1024 and up
                992: {
                    slidesPerView: 4,
                },
            },
            url: '',
            index: 0

        }
    },
    computed:{
        ...mapGetters({
            projectsDetails: "projectDetails/_getProjectDetails",
            mainImage: 'projectDetails/_getMainImage',
            local: '_getLocal',
        }),
    },
    methods:{
        openModal(url, index) {
            this.url = url;
            this.index = index;
            this.$refs.modal.openModal();
        },
        prevImg(image) {
            if(this.index === 0){
                this.index = image.length
            }
            if (this.index > 0) {
                this.index = this.index - 1;
                this.urls = image[this.index];
                this.url = this.urls.link
            } else {
                this.index = image.length - 1;
            }
        },
        nextImg(image) {
            this.index = this.index + 1;
            this.index = this.index % image.length;
                this.urls = image[this.index];
                this.url = this.urls.link;
        }
    },
    created() {
        this.$store.dispatch('projectDetails/getProjectsById',this.$route.params.id)
    }
}
