<style lang="scss" src="./project-details.component.scss"></style>
<template>
  <div class="detail mt-59">
<!--    <div class="detail_bg"></div>-->
    <div class="container">
      <card-component class="detail-info pb-25 pt-35 px-20">
        <div class="detail-info_body">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-12">
              <div class="detail-info_body_content">
                <div class="detail-info_body_content__icon">
                  <img :src="require('../../../../assets/images/clock-icon.svg')" alt="">
                  <span>{{local.projectDetails.status}}</span>
                </div>
                <div class="detail-info_body_content__text">
                  <p>{{projectsDetails.status}}</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="detail-info_body_content">
                <div class="detail-info_body_content__icon">
                  <img :src="require('../../../../assets/images/men-icon.svg')" alt="">
                  <span>{{local.projectDetails.customer}}</span>
                </div>
                <div class="detail-info_body_content__text">
                  <p>{{projectsDetails.customer}}</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="detail-info_body_content">
                <div class="detail-info_body_content__icon">
                  <img :src="require('../../../../assets/images/location-icon.svg')" alt="">
                  <span>{{local.projectDetails.address}}</span>
                </div>
                <div class="detail-info_body_content__text">
                  <p>{{projectsDetails.address}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card-component>
      <div class="row">
        <div class="col-12">
          <div class="mt-40 detail_information clearfix">
            <div v-if="mainImage && mainImage.link">
              <img :src="mainImage.link" alt="">
            </div>
            <p>{{local.projectDetails.ourValue}}</p>
            <h1 class="mt-20 mb-30"></h1>
            <span>{{projectsDetails.description}}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="detail_slider">
            <swiper
                :breakpoints="breakpoints"
                slides-per-view="4"
                :space-between="35"
                :navigation="{
                prevEl: '.slider__arrow--prev',
                nextEl: '.slider__arrow--next'
              }"
                spacebetween="35"
            >
              <div class="slider__wrapper">
                <div class="slider__arrow slider__arrow--prev">
                  <img alt="left" src="@/assets/images/arrow-left.svg">
                </div>
                <div class="slider__arrow slider__arrow--next">
                  <img alt="right" src="@/assets/images/arrow-right.svg">
                </div>
              </div>
              <swiper-slide
                  v-for="(information,index) in projectsDetails.fileUrls"
                  :key="index"
                  @click="openModal(information.link, index)"
              >
                <img  :src="information.link" alt="">
              </swiper-slide>
            </swiper>

          </div>
        </div>
      </div>
    </div>
  </div>
  <modal-component ref="modal">
    <div class="modal-gallery">
      <div class="modal-nav">
        <div class="slider__wrapper">
          <div class="slider__arrow slider__arrow--prev" @click="prevImg(projectsDetails.fileUrls)">
            <img alt="left" src="../../../../assets/images/modal-left.svg">
          </div>
          <div class="slider__arrow slider__arrow--next"  @click="nextImg(projectsDetails.fileUrls)">
            <img alt="right" src="../../../../assets/images/modal-right.svg">
          </div>
        </div>
      </div>
      <img class="img" :src="url" alt="">
    </div>
  </modal-component>
</template>

<script lang="js" src="./project-details.component.js"></script>
